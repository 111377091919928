<template>
  <main
    class="
      p-4
      relative
      w-full
      h-screen
      items-center
      flex
      flex-col
      justify-center
      bg-login-light
      lg:bg-login-light-wide
      dark:bg-login-dark
      dark:lg:bg-login-dark-wide
      bg-center
      bg-no-repeat
      bg-cover
      dark:text-gray-400
    "
  >
    <div v-if="!failed">
      <div>
        <GMLoader
          size="large"
          class="text-green-500 mx-auto"
        >
          <GMLogo
            :key="isAsUser"
            :name="isAsUser ? 'as-simple' : 'simple'"
            class="w-12 h-12 text-profileDarkGreen dark:text-profileLightGreen animate-pulse"
          />
        </GMLoader>
      </div>
      <div class="text-center">
        <div>
          {{ t('INITIALIZING_THE_APP') }}
        </div>
        <div class="text-sm">
          {{ t('THIS_COULD_TAKE_SOME_TIME') }}
        </div>

        <GMAlert
          v-if="currentUser"
          icon="user"
          class="my-3 text-left"
        >
          {{ currentUser.Name }}
          <div class="text-xs overflow-ellipsis">
            {{ currentUser.Email }}
          </div>
        </GMAlert>

        <div class="text-sm">
          {{ t('PLEASE_DONT_CLOSE_APP_BEFORE_READY') }}
        </div>
      </div>
    </div>
    <div v-if="failed">
      <p>
        {{ t('A_INITIALIZATION_ERROR_OCURRED') }}
      </p>

      <div
        v-if="wrongUserWarning"
        class="mt-1"
      >
        <GMAlert
          type="info"
          icon="user"
          class="max-w-2xl"
        >
          {{ t('error.WRONG_USER_ACCOUNT_LOGIN_MESSAGE') }}
        </GMAlert>

        <GMButton
          icon-before="lock"
          class="mt-3"
          primary
          @click="logOut"
        >
          {{ t('LOG_OUT_AND_IN_AGAIN') }}
        </GMButton>
      </div>

      <div v-else>
        <GMAlert
          icon="exclamation"
          type="error"
        >
          <pre>{{ errorMessage }}</pre>
        </GMAlert>

        <p>
          {{ t('MAKE_SURE_YOU_HAVE_A_STABLE_INTERNET_CONNECTION') }}
        </p>
        <GMButton
          type="button"
          class="mt-6"
          primary
          @click="retry"
        >
          {{ t('TRY_AGAIN') }}
        </GMButton>
      </div>
    </div>
  </main>
</template>

<script lang="ts">


import { useDataStore } from '~/use/data/store'
import { useUserStore } from '~/use/user/store'
import {GMAlert, GMButton, GMLoader, GMLogo} from '@gm/components';
import {PublicClientApplication} from '@azure/msal-browser';
import {definePageMeta} from '#imports';
export default defineComponent({
  components: {
    GMAlert,
    GMButton,
    GMLoader,
    GMLogo
  },
  setup (_, { emit }) {
    const { $msal } = useNuxtApp()
    const { updateDataInitial } = useDataStore()
    const { currentUser, isAsUser } = useUserStore()
    const { t } = useTranslation()

    definePageMeta({
      layout: 'clean'
    })

    const isDone = ref(false)
    const isLoading = ref(true)
    const failed = ref(false)
    const errorMessage = ref('')
    const wrongUserWarning = ref(false)

    const fetchData = async (): Promise<void> => {
      try {
        isDone.value = await updateDataInitial()
        emit('done')
      } catch (error) {
        console.log(error)
        failed.value = true
        errorMessage.value = error?.Message
          ? t(error.Message)
          : error.toString()

        // If error contants the message 'CRM_USER_NOT_FOUND' inform the user about changing accounts
        wrongUserWarning.value = error.toString().includes('CRM_USER_NOT_FOUND')

        throw error
      }
    }

    onMounted(() => fetchData())

    const reset = (): void => {
      errorMessage.value = ''
      wrongUserWarning.value = false
      failed.value = false
    }

    const retry = (): void => {
      // $auth.refreshTokens()
        ($msal as PublicClientApplication).logoutRedirect({
            postLogoutRedirectUri: window.location.origin
        })
      reset()
      fetchData()
    }

    const logOut = (): void => {
      reset();
      ($msal as PublicClientApplication).logoutRedirect({
          postLogoutRedirectUri: window.location.origin
      })
      // $auth.reset()
    }

    return {
      t,
      currentUser,
      errorMessage,
      failed,
      isAsUser,
      isDone,
      isLoading,
      logOut,
      retry,
      wrongUserWarning
    }
  }
})
</script>
